const removeCursor = () => {
  const className = "resizing";
  const html = document.getElementsByTagName("html").item(0);
  if (html && new RegExp(className).test(html.className) === true) {
    // Remove className with the added space (from setClassToHTMLElement)
    html.className = html.className.replace(new RegExp(" " + className), "");
    // Remove className without added space (just in case)
    html.className = html.className.replace(new RegExp(className), "");
  }
};
export default removeCursor;
