import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Amenities from "./components/Amenities";
import Apartments from "./components/Apartments";
import Contact from "./components/Contact";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="main">
        <Hero />
        <Services />
        <Amenities />
        <Apartments />
      </div>
      <Contact />
    </>
  );
};

export default Home;
