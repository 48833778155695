const calculateOpacity = (animate, percentage, type) => {
  if (type === "first") {
    if (percentage < 55) return 0;
    if (percentage > 65) return 1;
    // if (animate) return percentage * 0.01;

    return percentage * 0.01;
  }
  if (type === "second") {
    if (percentage > 45) return 0;
    if (percentage < 35) return 1;
    // if (animate) return (100 - percentage) * 0.01;
    return (100 - percentage) * 0.01;
  }
};
export default calculateOpacity;
