import { useState } from "react";

import NavbarDesktop from "./Desktop";
import NavbarMobile from "./Mobile";
import MobileMenu from "./MobileMenu";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);

  return (
    <div className="container-fluid navbar-container">
      <NavbarMobile toggleMenu={toggleMenu} isShown={menu} />
      <NavbarDesktop />
      <MobileMenu isShown={menu} toggleMenu={toggleMenu} />
    </div>
  );
};

export default Navbar;
