import { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import HERO_IMAGE from "../../assets/images/hero/hero-image.jpg";
import Title from "./Title";

const Hero = () => {
  const [imageScale, setScale] = useState(1);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -1920) return;
      // setPos(currPos.y);
      setScale(1 - currPos.y * 0.0003);
    },
    null,
    null,
    false,
    0
  );

  return (
    <div className="hero-container container-fluid">
      <div className="hero_image-wrapper">
        <div className="title-clipper">
          <div className="clip">
            <div className="title-holder">
              <Title />
            </div>
          </div>
        </div>
        <img
          src={HERO_IMAGE}
          alt="hero"
          className="hero_image"
          style={{ transform: `scale(${imageScale})` }}
        />
      </div>
      <div className="hero-title-wrapper">
        <div className="title-holder">
          <Title className="dark-title" />
        </div>
      </div>
      <div className="hero-content">
        <p className="text">
          Lease your home with ease, your terms, your choice.
        </p>
      </div>
    </div>
  );
};

export default Hero;
