const Thumb = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="7.8px"
      viewBox="0 0 188.3 74.1"
      style={{ overflow: "visible", enableBackground: `new 0 0 188.3 74.1` }}
    >
      <defs></defs>
      <path
        d="M66.5,37.1v28.5c0,6.6-7.1,10.7-12.8,7.4L29,58.7L4.3,44.5c-5.7-3.3-5.7-11.5,0-14.8L29,15.4L53.7,1.2
	C59.3-2.1,66.5,2,66.5,8.6V37.1z"
      />
      <path
        d="M121.8,37.1V8.6c0-6.6,7.1-10.7,12.8-7.4l24.7,14.3L184,29.7c5.7,3.3,5.7,11.5,0,14.8l-24.7,14.3L134.7,73
	c-5.7,3.3-12.8-0.8-12.8-7.4V37.1z"
      />
    </svg>
  );
};
export default Thumb;
