import Logo from "../../assets/images/logo/logo-light.png";

const ContactUs = () => {
  const mail = `sales@ustella.com`;
  const phone = `+971 58 882 0404`;

  const address = `Stella Tower, Abdullah Omran Taryam St - Barsha Heights, Dubai - United Arab Emirates`;
  const addressLink = `https://www.google.com/maps/place/Stella+Tower/@25.0968022,55.1712827,15z/data=!4m2!3m1!1s0x0:0x2e5141a8ad3c9aa6?sa=X&ved=2ahUKEwirld_WkeLxAhVVhv0HHdbbAlYQ_BIwCnoECEcQBQ`;

  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="container-fluid contact-container" id="contact-us">
      <h2 className="title">Reach out to us</h2>
      <p className="description">We are here to help. Always.</p>

      <div className="contact-items">
        <div className="contact-item phone">
          <a href={`tel:${phone}`}>
            <i className="bi-phone" />
            <span>{phone}</span>
          </a>
        </div>

        <div className="contact-item maps">
          <a href={addressLink} target="_blank" rel="noopener noreferrer">
            <i className="bi-map" />
            <span>{address}</span>
          </a>
        </div>

        <div className="contact-item mail">
          <a href={`mailto:${mail}`}>
            <i className="bi-envelope" />
            <span>{mail}</span>
          </a>
        </div>
      </div>

      <div className="bottom">
        <div className="container">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <div className="copyright">{year} UStella - All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
