const Maintenace = () => {
  return (
    <svg
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9031 23.7787L25.7485 14.8856C27.1164 15.392 28.6002 15.4946 30.0245 15.1813C31.4487 14.868 32.7536 14.1519 33.785 13.1176C35.2631 11.628 35.9598 9.85787 36.0805 7.79238L34.6468 9.26713L29.9626 10.8396L25.1577 6.0435L26.801 1.42375L28.224 0C26.2207 0.16575 24.4144 0.765 22.9574 2.227C21.9373 3.25841 21.2289 4.55847 20.9141 5.97689C20.5993 7.39531 20.691 8.87417 21.1786 10.2425L12.3925 19.0782C9.71154 18.1092 6.59648 18.8296 4.41954 21.0205C2.9963 22.4529 2.19708 24.3931 2.19708 26.4159C2.19708 28.4386 2.9963 30.3788 4.41954 31.8113C7.35672 34.765 12.1574 34.7225 15.1475 31.7156C17.2694 29.5842 17.7544 26.4223 16.9052 23.7766L16.9031 23.7787ZM5.78542 30.3662C5.27978 29.8762 4.87646 29.29 4.599 28.6418C4.32154 27.9937 4.1755 27.2966 4.16939 26.5912C4.16328 25.8858 4.29723 25.1863 4.56342 24.5334C4.82962 23.8805 5.22272 23.2873 5.7198 22.7885C6.21688 22.2897 6.80798 21.8953 7.45861 21.6281C8.10923 21.361 8.80636 21.2266 9.50931 21.2327C10.2123 21.2389 10.907 21.3854 11.5529 21.6638C12.1988 21.9423 12.7829 22.347 13.2713 22.8544C14.2247 23.8582 14.7491 25.1964 14.7326 26.583C14.7161 27.9696 14.1599 29.2948 13.1829 30.2755C12.2059 31.2562 10.8855 31.8146 9.50365 31.8316C8.12183 31.8486 6.78818 31.3227 5.78754 30.3662H5.78542Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Maintenace;
