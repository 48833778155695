import { useEffect, useRef, useState } from "react";

import FURNISHED from "../../assets/images/services/furnished.jpg";
import UNFURNISHED from "../../assets/images/services/unfurnished.jpg";

import isTouchDevice from "is-touch-device";

import addCursor from "./addCursor";
import removeCursor from "./removeCursor";
import calculateOpacity from "./calculateOpacity";
import Thumb from "./Thumb";
import useOnScreen from "../hooks/useOnScreen";
import useWindowSize from "../hooks/useWindowSize";

let dragImage = new Image(0, 0);
dragImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

const Services = () => {
  const [overlay, setOverlay] = useState(50);
  const firstImage = useRef();
  const secondImage = useRef();
  const windowWidth = useWindowSize().width;

  const isTouch = isTouchDevice();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);
  const [secondImageLoaded, setSecondImageLoaded] = useState(false);
  const containerRef = useRef(null);

  const isVisible = useOnScreen(containerRef);
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    if (!isVisible) return;
    if (isDirty) return;

    const interval = setInterval(() => {
      setOverlay((o) => o + 4);
      setTimeout(() => setOverlay((o) => o - 8), 500);
      setTimeout(() => setOverlay((o) => o + 4), 1000);
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, [isDirty, isVisible]);

  const [refWidth, setRefWidth] = useState({ half: 0, width: 0 });
  useEffect(() => {
    const { width } = containerRef.current.getBoundingClientRect();
    const freeSpace = windowWidth - width;
    const half = freeSpace / 2;
    setRefWidth({ half: half, width: width });
  }, [windowWidth, containerRef]);

  useEffect(() => {
    function handleMouseUp(e) {
      setIsMouseDown(false);
      if (
        containerRef.current &&
        !firstImage.current.contains(e.target) &&
        !secondImage.current.contains(e.target) &&
        !isMouseDown
      ) {
        return;
      }

      removeCursor();

      const clickX = isTouch ? e.clientX : e.screenX;

      let ratio = 0;
      if (clickX < refWidth.half) ratio = 0;
      else if (clickX > refWidth.half + refWidth.width) ratio = 100;
      else ratio = ((clickX - refWidth.half) * 100) / refWidth.width;

      if (ratio > 42 && ratio < 58) ratio = 50;
      else if (ratio > 50) ratio = 90;
      else if (ratio < 50) ratio = 10;

      if (!isMouseDown) {
        if (ratio < 50 && overlay > 50) ratio = 10;
        else if (ratio > 50 && overlay < 50) ratio = 90;
        else if (ratio > 50) ratio = 10;
        else if (ratio < 50) ratio = 90;
      }

      setOverlay(ratio);
    }

    function handleMouseMove(e) {
      if (!isMouseDown) return;

      let ratio = 0;
      ratio = ((e.pageX - refWidth.half) * 100) / refWidth.width;
      if (ratio > 95) ratio = 95;
      if (ratio < 5) ratio = 5;

      setOverlay(ratio);
    }

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isMouseDown, isTouch, overlay, refWidth]);

  useEffect(() => {
    if (isVisible) return;
    setOverlay(50);
    setDirty(false);
    setIsMouseDown(false);
  }, [isVisible]);

  const handleMouseDown = (e) => {
    if (!firstImageLoaded || !secondImageLoaded) return;

    e.stopPropagation();
    e.preventDefault();

    addCursor();
    setIsMouseDown(true);
  };
  const thumbStyle = { marginLeft: `${overlay}%` };
  return (
    <div
      className="container services-container"
      id="services"
      onClick={() => setDirty(true)}
    >
      <div className="service-compare-container" ref={containerRef}>
        {isTouch && !isDirty && (
          <div className="select-tooltip">Let's tap on an image!</div>
        )}
        <div className={`service-compare ${!isMouseDown ? "animate" : ""}`}>
          <div
            className="thumb"
            style={thumbStyle}
            onMouseDown={handleMouseDown}
          >
            {windowWidth > 768 && (
              <div className="thumb-icon">
                <span>
                  <Thumb />
                </span>
              </div>
            )}
          </div>
          <div
            className="service service-furnished"
            style={{ width: `${overlay}%` }}
          >
            <div
              className="gradient"
              style={{ opacity: overlay === 50 ? 0 : overlay * 0.005 }}
            />
            <img
              draggable={false}
              src={UNFURNISHED}
              alt="service-furnished"
              ref={firstImage}
              onLoad={() => setFirstImageLoaded(true)}
            />
          </div>

          <div className="service service-unfurnished">
            <div
              className="gradient"
              style={{ opacity: overlay === 50 ? 0 : 1 - overlay * 0.05 }}
            />
            <img
              draggable={false}
              src={FURNISHED}
              alt="service-unfurnished"
              ref={secondImage}
              onLoad={() => setSecondImageLoaded(true)}
            />
          </div>

          <div className="service-lists">
            <div
              className="service-list furnished"
              style={{
                marginLeft: `${overlay * 0.33 - 30}%`,
                opacity: calculateOpacity(!isMouseDown, overlay, "first"),
              }}
            >
              <div className="content">
                <h4 className="title">Unfurnished</h4>
                <ul className="ul">
                  <li>Flexible lease</li>
                  <li>Multiple cheques</li>
                  <li>Credit card payments</li>
                  <li>Luxury amenities</li>
                  <li>Personalized renting experience</li>
                </ul>
              </div>
            </div>

            <div
              className="service-list unfurnished"
              style={{
                marginRight: `${-overlay * 0.33 + 3}%`,
                opacity: calculateOpacity(!isMouseDown, overlay, "second"),
              }}
            >
              <div className="content">
                <h4 className="title">Furnished</h4>
                <ul className="ul">
                  <li>24/7 guest support</li>
                  <li>Modern furniture</li>
                  <li>Self check-in process</li>
                  <li>Fully stocked kitchen</li>
                  <li>Nespresso machine</li>
                  <li>Signature toiletries</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inquire-holder">
        <button
          className="inquire"
          onClick={() =>
            window.scrollTo({
              top: document.body.scrollHeight,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          Let's get started!
        </button>
      </div>
    </div>
  );
};
export default Services;
