const Title = (props) => {
  const { className } = props;
  const title = [`A better`, `way`, `to rent`];

  return (
    <div className={`hero-title ${className || ""}`}>
      {title.map((t, i) => (
        <span className={`title-${i + 1}`} key={i}>
          {t}
        </span>
      ))}
    </div>
  );
};

export default Title;
