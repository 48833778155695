import IMAGES from "./Images";

import Masonry from "react-masonry-css";
const breakpointColumnsObj = {
  default: 2,
  1200: 2,
  768: 1,
};

const ImageModal = (props) => {
  const { toggle, show } = props;

  return (
    <>
      <div className={`amenities-images-modal ${show ? "show" : ""}`}>
        <div className="content">
          <div className="header">
            <h5 className="title">Facilities</h5>
            <button className="x" onClick={toggle}>
              <i className="bi-x"></i>
            </button>
          </div>
          <div className="images">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="masonryGrid"
              columnClassName="masonryColumn"
            >
              {IMAGES.map((image, index) => {
                return (
                  <div className="image" key={index}>
                    <img src={image} alt="" />
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
      <div className={`modal-bg ${show ? "show" : ""}`}></div>
    </>
  );
};

export default ImageModal;
