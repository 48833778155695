import AnchorLink from "react-anchor-link-smooth-scroll";

const MobileMenu = ({ isShown, toggleMenu }) => {
  const ITEMS = [
    {
      key: "services",
      label: "Services",
    },
    {
      key: "amenities",
      label: "Facilities",
    },
    {
      key: "apartments",
      label: "Apartments",
    },
    {
      key: "contact-us",
      label: "Contact us",
      function: () =>
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: "smooth",
        }),
    },
    // {
    //   key: "",
    //   label: "Login",
    //   href: `https://stellastays.com`,
    // },
  ];

  return (
    <div className={`mobile-menu ${isShown ? "shown" : ""}`}>
      <div className="list quick-menu">
        {ITEMS.map((item, index) => {
          const delay = ITEMS.length * 100 - (ITEMS.length + index * 100) + 250;

          return (
            <li
              key={index}
              className={`list__item-${index + 1}`}
              style={{
                transitionDelay: `${delay}ms`,
              }}
              onClick={() => (item.href ? null : toggleMenu())}
            >
              {item.function ? (
                <button onClick={item.function}>{item.label}</button>
              ) : (
                <>
                  {item.href ? (
                    <a href={item.href}>{item.label}</a>
                  ) : (
                    <AnchorLink href={`#${item.key}`}>{item.label}</AnchorLink>
                  )}
                </>
              )}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default MobileMenu;
