import { useState } from "react";

import LOGO_DARK from "../../assets/images/logo/logo-dark.png";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const NavbarMobile = (props) => {
  const { toggleMenu, isShown } = props;
  const [bg, setBg] = useState(false);
  const [direction, setDirection] = useState("down");

  useScrollPosition(({ prevPos, currPos }) => {
    const pre = prevPos.y;
    const pos = currPos.y;
    if (pos < -1200) {
      if (bg === false) setBg(true);
    } else {
      if (bg === true) setBg(false);
    }

    if (pre > pos) {
      if (direction === "up") setDirection("down");
    } else {
      if (direction === "down") setDirection("up");
    }
  });

  return (
    <div
      className={`navbar-content mobile
			 ${!isShown && bg ? "background" : ""} ${!isShown ? direction : "down"} `}
    >
      <div className={`logo ${isShown ? "menu-shown" : ""}`}>
        <img src={LOGO_DARK} alt="logo" />
      </div>

      <div className="mobile-menu-button-wrapper">
        <button
          className={`mobile-menu-button ${isShown ? "shown" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </button>
      </div>
    </div>
  );
};

export default NavbarMobile;
