const Furniture = () => {
  return (
    <svg
      width="35"
      height="26"
      viewBox="0 0 35 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9 11.375V14.625H26V11.375C26 9.58242 27.5247 8.125 29.4 8.125H31.1C31.1 5.43359 28.8156 3.25 26 3.25H9C6.18438 3.25 3.9 5.43359 3.9 8.125H5.6C7.47531 8.125 9 9.58242 9 11.375ZM31.1 9.75H29.4C28.4597 9.75 27.7 10.4762 27.7 11.375V16.25H7.3V11.375C7.3 10.4762 6.54031 9.75 5.6 9.75H3.9C2.02469 9.75 0.5 11.2074 0.5 13C0.5 14.1984 1.19063 15.2344 2.2 15.798V21.9375C2.2 22.3844 2.5825 22.75 3.05 22.75H6.45C6.9175 22.75 7.3 22.3844 7.3 21.9375V21.125H27.7V21.9375C27.7 22.3844 28.0825 22.75 28.55 22.75H31.95C32.4175 22.75 32.8 22.3844 32.8 21.9375V15.798C33.8094 15.2344 34.5 14.1984 34.5 13C34.5 11.2074 32.9753 9.75 31.1 9.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="34"
            height="26"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Furniture;
