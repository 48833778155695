import A1 from "../../assets/images/apartments/apartment-1.jpg";
import A2 from "../../assets/images/apartments/apartment-2.jpg";
import A3 from "../../assets/images/apartments/apartment-3.jpg";
import A4 from "../../assets/images/apartments/apartment-4.jpg";
import A5 from "../../assets/images/apartments/apartment-5.jpg";
import A6 from "../../assets/images/apartments/apartment-6.jpg";
import A7 from "../../assets/images/apartments/apartment-7.jpg";
import A8 from "../../assets/images/apartments/apartment-8.jpg";
import A9 from "../../assets/images/apartments/apartment-9.jpg";
import A10 from "../../assets/images/apartments/apartment-10.jpg";
import A11 from "../../assets/images/apartments/apartment-11.jpg";
import A12 from "../../assets/images/apartments/apartment-12.jpg";
import A13 from "../../assets/images/apartments/apartment-13.jpg";

const IMAGES = [
  [A1, A2, A3, A4, A5, A6, A7],
  [A8, A9, A10, A11, A12, A13],
];
export default IMAGES;
