import AnchorLink from "react-anchor-link-smooth-scroll";
import LOGO_DARK from "../../assets/images/logo/logo-dark.png";

const NavbarDesktop = () => {
  const ITEMS = [
    {
      key: "services",
      label: "Services",
    },
    {
      key: "amenities",
      label: "Facilities",
    },
    {
      key: "apartments",
      label: "Apartments",
    },
    {
      key: "contact-us",
      label: "Contact us",
      function: () =>
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: "smooth",
        }),
    },
  ];
  return (
    <div className="navbar-content desktop">
      <div className="logo">
        <img src={LOGO_DARK} alt="logo" />
      </div>
      <div className="navbar-items">
        {ITEMS.map((item, index) => {
          return (
            <div key={index} className="item">
              {item.function ? (
                <button onClick={item.function}>{item.label}</button>
              ) : (
                <AnchorLink href={`#${item.key}`}>{item.label}</AnchorLink>
              )}
            </div>
          );
        })}
      </div>

      <div></div>
      {/* <div className="login">
        <a
          href={`https://stellastays.com`}
          target="_blank"
          className="login-button"
          rel="noopener noreferrer"
        >
          Login
        </a>
      </div> */}
    </div>
  );
};

export default NavbarDesktop;
